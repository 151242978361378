import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getApiDomain } from "../config";
import "./ConfigView.css";
import { GreyCheckMark, GreenCheckMark } from "../assets/images";
export default function ConfigView(props: { token: string }) {
  const [showcode, setShowcode] = useState<string>("");

  const [configLoaded, setConfigLoaded] = useState<boolean>(false);

  const [recipients, setRecipients] = useState<string[]>([]);
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);

  const [checkedRules, setCheckedRules] = useState<string[]>([]);
  //
  const [publicApiKey, setPublicApiKey] = useState<string>("");
  const [privateApiKey, setPrivateApiKey] = useState<string>("");
  //
  const [validationRules, setValidationRules] = useState<boolean>(false);
  const [validationrecipients, setValidationrecipients] = useState<boolean>(true);
  const [validationTracking, setValidationTracking] = useState<boolean>(false);
  const [validationNotifications, setValidationNotifications] = useState<boolean>(false);

  const rules = [
    { id: "userweek", name: "A user has been inactive for one week." },
    { id: "usermonth", name: "A user has been inactive for one month." },
    {
      id: "groupweek",
      name: "Most users in a group have been inactive for a week.",
    },
    {
      id: "groupmonth",
      name: "Most users in a group have been inactive for a month.",
    },
  ];

  useEffect(() => {
    async function loadConfig() {
      console.log("loadConfig");
      try {
        let response = await axios.get(getApiDomain() + "/private/userConfig");
        setRecipients(response.data?.recipients || []);
        setCheckedRules(response.data?.rules || []);
        setValidationTracking(response.data?.trackingOK || false);
        setValidationNotifications(response.data?.notificationsOK || false);
        setPublicApiKey(response.data?.keys?.publicApiKey || "");
        setPrivateApiKey(response.data?.keys?.privateApiKey || "");
        setTimeout(() => setConfigLoaded(true), 500);
      } catch (err) {
        console.log(err);
      }
    }
    loadConfig();
  }, []);

  const updateConfig = useCallback(async () => {
    try {
      if (!configLoaded) {
        return;
      }
      await axios.post(getApiDomain() + "/private/userConfig", {
        recipients: recipients,
        rules: checkedRules,
      });
    } catch (err) {
      console.log(err);
    }
  }, [configLoaded, recipients, checkedRules]);

  const addEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !isEmailValid) {
      return false;
    }
    try {
      const newList = [...recipients, email];
      setRecipients(newList);
      setEmail("");
    } catch (err) {
      console.log(err);
    }
    return false;
  };
  const removeEmail = async (i: number) => {
    const newList = recipients.filter((_, index) => index !== i);
    setRecipients(newList);
  };

  useEffect(() => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsEmailValid(!email || !!email.match(re));
  }, [email]);

  useEffect(() => {
    updateConfig();
  }, [updateConfig, recipients, checkedRules]);


    /// validations
  useEffect(() => {
    setValidationRules(checkedRules.length > 0);
  }, [checkedRules]);
  useEffect(() => {
    setValidationrecipients(recipients.length > 0);
  }, [recipients]);

  const code1 = `<script src="https://sdk.churnshield.yoctonet.com/churnshield.min.js"></script>`;

  const code2 = `
    churnshield.heartbeat(
      "${publicApiKey}", // your frontend api key
      {
        userId: // email, username or id of the user
        groupId: // (optional) id or name of the customer/group of users
      },
    );`;
    const codeb1 = `
    POST https://api.churnshield.yoctonet.com/v1/heartbeat
    body: {
      apiKey: "${privateApiKey}" // your backend api key
      userId: // email, username or id of the user
      groupId: // (optional)  id or name of the customer/group of users
      }
    `;
    const codeb3 = `
    POST https://api.churnshield.yoctonet.com/v1/doNotTrack
    body: {
      apiKey: "${privateApiKey}" // your backend api key
      userId: // email, username or id of the user
      groupId: // (optional) id or name of the customer/group of users
    }
    `;
    const codeb4 = `
    POST https://api.churnshield.yoctonet.com/v1/doNotTrack
    body: {
      apiKey: "${privateApiKey}" // your backend api key
      groupId: // id or name of the customer/group of users
    }
    `;
  return !configLoaded ? (
    <div>Loading...</div>
  ) : (
    <>
      <section>
        <h3>1. Insert those snippets of code</h3>

        <p
        >
          If you will track your users from your frontend
          <span className="showcode" onClick={() => setShowcode(showcode === "front" ? "": "front")}> click here</span>
          <br/>
          If you will track your users from your server
          <span className="showcode" onClick={() => setShowcode(showcode === "back" ? "": "back")} > click here</span>
          <br/>
        

        </p>
        <div style={{ display: showcode === "front" ? "block" : "none" }}>
          <p><b>Frontend tracking</b></p>
          <p>Include Churnshield</p>
          <div className="code-block">
            <CopyToClipboard text={code1}>
              <div className="copy-button">Copy</div>
            </CopyToClipboard>
            <pre> {code1} </pre>
          </div>
          <p>
            Call the function below every time an user is active (for example after
            login or after a relevant action)
        <br/>
            You can group your users
            (for example users of the same customer), and be notified when the
            majority of the group is inactive.
          </p>
          <div className="code-block">
            <CopyToClipboard text={code2}>
              <div className="copy-button">Copy</div>
            </CopyToClipboard>
            <pre> {code2} </pre>
          </div>
          
        </div>
        <div style={{ display: showcode === "back" ? "block" : "none" }}>
          <p><b>Backend tracking</b></p>
          <p>
            Send a POST request every time an user is active (for example after
            login or after a relevant action)
            <br/>
            You can group your users
            (for example users of the same customer), and be notified when the
            majority of the group is inactive.
          </p>
          <div className="code-block">
            <CopyToClipboard text={codeb1}>
              <div className="copy-button">Copy</div>
            </CopyToClipboard>
            <pre> {codeb1} </pre>
          </div>
          <p>
            To stop tracking an user
          </p>
          <div className="code-block">
            <CopyToClipboard text={codeb3}>
              <div className="copy-button">Copy</div>
            </CopyToClipboard>
            <pre> {codeb3} </pre>
          </div>
          <p>
            To stop tracking every users of a group
          </p>
          <div className="code-block">
            <CopyToClipboard text={codeb4}>
              <div className="copy-button">Copy</div>
            </CopyToClipboard>
            <pre> {codeb4} </pre>
          </div>
        </div>
      </section>
      <section>
        <h3>2. Set up the rules triggering the notifications</h3>
        <p>Send a notification when:</p>
        <ul className="rules">
          {rules.map((rule, index) => (
            <li key={rule.id}>
              <input
                type="checkbox"
                id={rule.id}
                checked={checkedRules.includes(rule.id)}
                onChange={() => {
                  if (checkedRules.includes(rule.id)) {
                    setCheckedRules(checkedRules.filter((r) => r !== rule.id));
                  } else {
                    setCheckedRules([...checkedRules, rule.id]);
                  }
                }}
              />
              <label htmlFor={rule.id}>{rule.name}</label>
            </li>
          ))}
        </ul>
      </section>
      <section>
        <h3>3. Chose who receive the notification:</h3>

        <form className="add" onSubmit={addEmail}>
          <input
            type="text"
            name="add"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Add an email..."
          />
        </form>
        {!isEmailValid && <i className="invalidEmail">Email invalid</i>}
        <ul className="recipients">
          {recipients.map((email, i) => (
            <li key={i}>
              <span className="email">{email}</span>
              <span
                className="remove"
                onClick={() => {
                  removeEmail(i);
                }}
              >
                remove
              </span>
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h3>4. Validation Checks</h3>
        <ul className="validation-checks">
            <li>
                {validationRules?<img className="separator-line" src={GreenCheckMark} alt="yes" />:  <img className="separator-line" src={GreyCheckMark} alt="no" />}
                You have configured rules to trigger notifications
            </li>
            <li>
                {validationrecipients?<img className="separator-line" src={GreenCheckMark} alt="yes" />:  <img className="separator-line" src={GreyCheckMark} alt="no" />}
                You have configured recipients adress to receive notifications
            </li>
            <li>
                {validationTracking?<img className="separator-line" src={GreenCheckMark} alt="yes" />:  <img className="separator-line" src={GreyCheckMark} alt="no" />}
                You are using our javascript tracking or our api, and we have received user data
            </li>
            <li>
                {validationNotifications?<img className="separator-line" src={GreenCheckMark} alt="yes" />:  <img className="separator-line" src={GreyCheckMark} alt="no" />}
                We detected inactivity and sent you one notification
            </li>
        </ul>
      </section>
    </>
  );
}
